<template>
  <div class="site-footer">
    <div class="inbox footer-links">
      <a href="">关于我们</a>|<a href="">联系我们</a>|<a href="">联系客服</a>|<a href="">供货商合作</a>|<a href="">帮助中心</a>|<a href="">隐私政策</a>
    </div>
  </div>
</template>

<script>
import {_data, _methods} from '@/common/public'

export default {
  props: {
  },
  data() {
    return {
      ..._data,
      systemConfig:{
        title:"智业福利网"
      },
      userInfo: {realname: '',nickname:'',username:''},
    }
  },
  created() {
  },
  methods: {
    ..._methods,
  },
}
</script>

<style lang="scss" scoped>

.site-footer{
  border-top:#ddd solid 1px;
  margin-top: 26px;
}

.footer-links{
  padding: 20px 0;
  text-align: center;
  a{
    display: inline-block;
    margin: 0 8px;
  }
}
</style>
