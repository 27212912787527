<template>
  <el-dialog width="420px" v-model="showDialog" title="登录" :close-on-click-modal="false">
    <form action="" name="formSubmit" id="formSubmit" @submit.prevent="submit">
      <ul class="form-list">
        <li>
          <el-input v-model="model.username" placeholder="输入用户名"></el-input>
        </li>

        <li>
        <el-input type="password" v-model="model.password" placeholder="输入密码"></el-input>
        </li>

        <li class="img-code-item">
          <el-input type="text" v-model="model.imgCode" placeholder="图形验证码"></el-input>
          <span class="img-code" @click="changeImgCode"><img :src="imgCodeUrl"></span>
        </li>
        <li class="btns">
          <button type="submit" class="btn-img1" :disabled="loading" v-loading="loading">进入系统</button>
        </li>
      </ul>
    </form>
  </el-dialog>
</template>

<script>
import Services from "../../services/login.js";
import {_data, _methods} from "@/common/public";
import {DataValidation} from "@/common/dataValidation";
const dataValidation = new DataValidation();

export default {
  name: "LoginCom",
  components: {
  },
  data() {
    return {
      ..._data,
      showDialog: false,
      model: {
        username: '',
        password: '',
        imgCode: '',
        imgCodeToken: '',
        imgCodeId: 0
      },
      confirmPassword: '',
      imgCodeUrl: '',
      loading: false,
      rules: [
        {name: "username", checkType: "required", errorMsg: "用户名不能为空"},
        {name: "password", checkType: "required", errorMsg: "密码不能为空"},
        {name: "imgCode", checkType: "required", errorMsg: "图形验证码不能为空"},
      ],
    };
  },
  methods: {
    ..._methods,
    show(){
      this.showDialog = true
      this.getImgCode()
    },
    close(){
      this.showDialog = false
    },
    submit() {
      this.loading = true;

      let v = dataValidation.validate(this.model, this.rules);
      if (!v.isOK) {
        this.$message.error(v.errorMsg);
        this.loading = false;
        return false;
      }

      Services.login(this.model).then(response => {
        this.loading = false;
        let res = response.data;
        console.log('login', response);
        if (res.Flag) {
          this.setLoginToken(res.Data,response)
          setTimeout(()=>{
            location.reload()
          },100)
        } else {
          this.getImgCode()
        }
      })
    },
    getImgCode() {
      Services.getImgCode().then(res => {
        this.imgCodeUrl = `${res.Data}`; // data:image/png;base64,
        console.log(this.imgCodeUrl)
        this.model.imgCodeToken = res.Token;
        this.model.imgCodeId = res.Id;
      })
    },
    changeImgCode() {
      this.getImgCode()
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  height: 40px;
  border: none;
  background-color: rgba(255, 112, 68,1);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  &:hover{
    background-color: rgba(255, 112, 68,0.8);
  }
}

.img-code-item {
  display: flex;
}

.form-list {
  li{
    margin-bottom: 12px;
  }
}

.img-code {
  width: 100px;
  height: 40px;
  margin-left: 6px;
  cursor: pointer;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
  }
}

.form-help {
  font-size: 14px;
}

</style>
