<template>
  <div class="site-tools">
    <div class="inbox">
      <div class="start">
        <router-link to="/" class="site-name">{{ systemConfig.title }}</router-link>
      </div>
      <div class="end">
        <a class="col login-register" v-if="!userInfo.Id" @click="showLoginCom">登录</a>

        <router-link to="/admin/index" class="col user-name" v-if="userInfo.Id">
          HI，{{ userInfo.realname || userInfo.nickname || userInfo.username }}
        </router-link>

        <router-link to="/goods/cart" class="col my-cart em" v-if="userInfo.Id">
          我的进货单<var>(<span id="cartCount">{{systemConfig.cartCount}}</span>)</var>
        </router-link>
        <span class="col switch-user" @click="logout" v-if="userInfo.Id">退出用户</span>
      </div>
    </div>
  </div>

  <div class="site-header">
    <div class="inbox">
      <div class="start">
        <router-link to="/" class="site-name">{{ systemConfig.title }}</router-link>
      </div>
      <div class="center">
        <form action="" class="site-search">
          <div class="search-box">
            <div class="keyword-box">
              <i class="icon-search"></i>
              <input type="text" value="" placeholder="输入关键字 搜索商品">
            </div>
            <button type="submit" class="search-button">搜 索</button>
          </div>
        </form>

        <div class="hot-keys" style="display: none"></div>
      </div>
      <div class="end">
        <a class="col login-register" v-if="!userInfo.Id" @click="showLoginCom"><i class="icon-head"></i> 登录</a>
        <a href="" class="col go-top"><i class="icon-rexiao1"></i> 排行榜</a>
      </div>
    </div>
  </div>

  <div class="main-nav">
    <div class="inbox">
      <div class="tit"><span>产品分类</span></div>
      <ul>
        <li v-for="(item,index) in mainNav" :key="index">
          <router-link :to="item.path_web" class="item">{{ item.cname }}</router-link>
        </li>
      </ul>
    </div>
  </div>

  <loginCom ref="loginCom"></loginCom>
</template>

<script>
import {_data, _methods, util} from '@/common/public'
import loginCom from "@/views/login/loginCom";

export default {
  components: {
    loginCom
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ..._data,
      systemConfig: {
        title: "智业福利网",
        cartCount:0,
      },
      userInfo: {Id: "", real_name: '', nickname: '', username: ''},
      mainNav:[],
      classNav:[]
    }
  },
  created() {
    this.userInfo = util.getLocalStorage('userInfo', {});
    this.systemConfig = util.getLocalStorage('systemConfig',{})
    this.mainNav = util.getLocalStorage('mainNav',[])
    this.classNav = util.getLocalStorage('classNav',[])
    console.log("this.systemConfig",this.systemConfig,this.userInfo)
  },
  mounted() {
    console.log("$route",this.$route)
  },
  methods: {
    ..._methods,
    logout() {
      util.setLocalStorage("TOKEN", "")
      util.setLocalStorage("userInfo", "")
      util.setLocalStorage('userConfig', "");
      if(this.$route.name==="index"){
        location.reload()
      }else{
        this.$router.push({path: '/'});
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../css/_var.scss';

.site-tools {
  border-bottom: #eee solid 1px;
  background-color: rgb(54, 54, 54);
  color: #999;

  a {
    color: #999;

    &:hover {
      color: var(--color-primary);
    }
  }

  .inbox {
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    display: flex;
  }

  .start {
    width: 200px;
  }

  .end {
    flex: 1;
    text-align: right;

    .col {
      margin-left: 14px;
    }

    .my-cart {
      position: relative;

      var {

      }
    }

    .badge-number {
      display: inline-block;
      position: static;
    }
  }
}

$siteHeaderHeight: 76px;
.site-header {
  background: #fff;
  height: $siteHeaderHeight;
  .inbox {
    display: flex;
  }
  .start {
    width: 300px;
    height: $siteHeaderHeight;
    display: flex;
    align-items: center;
    .site-name {
      font-size: 28px;
      line-height: 28px;
      color: #ff4000;
      font-weight: 700;
    }
  }
  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 18px;
    .site-search {
      width: 100%;
    }
    .hot-keys{
      width: 100%;
      margin-top: 5px;
      text-align: left;
    }
  }

  .end {
    text-align: right;
    width: 300px;
    height: $siteHeaderHeight;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;

    .col {
      margin-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    .login-register {
      padding: 0 10px;
    }

    [class^='icon-'] {
      font-size: 22px;
      margin-right: 6px;
      color: var(--color-primary);
    }
  }

}

.search-box {
  display: flex;

  .keyword-box {
    flex: 1;
    position: relative;
  }

  input {
    width: 100%;
    border: #ff4000 solid 2px;
    border-radius: 4px;
    height: 46px;
    padding: 0 22px 0 40px;
    box-sizing: border-box;
    font-size: 16px;
    position: relative;
    z-index: 1;
  }

  .search-button {
    width: 100px;
    height: 46px;
    background-color: transparent;
    border: none;
    background-image: linear-gradient(90deg, #ff7e3e, #ff4000);
    border-radius: 0 6px 6px 0;
    margin-left: -10px;
    color: #fff;
    font-size: 16px;
    position: relative;
    z-index: 3;
  }

  .icon-search {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 40px;
    line-height: 46px;
    text-align: center;
    font-size: 20px;
    color: #999;
  }
}

.site-header-placeholder {
  height: $siteHeaderHeight;
  margin-bottom: 16px;
}

.switch-user {
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: var(--color-primary);
  }
}

.main-nav{
  height: 50px;
  background: #fff;
  margin-bottom: 16px;
  font-weight: 700;
  .inbox{
    height: 100%;
    display: flex;
  }
  .tit{
    display: none;
    width: 220px;
    text-align: center;
    font-size: 16px;
    span{
      display: block;
      line-height: 50px;
      background: #f9f9f9;
    }
  }
  ul{
    height: 100%;
    display: flex;
    li{
      height: 100%;
    }
    .item{
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 0 36px;
    }
  }
}
</style>
