/**
 * Created by Administrator on 2020/11/26.
 */
import {$http} from '@/common/axios';
import {ServicesGlobal} from './services.js';

class LoginServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    // 登录
    login(data) {
        let params = this.postParams(data);
        return $http.post(`{admin}/admin/login`, params)
            .then(function (response) {
                return response;
            });
    }
}


export default (new LoginServices())
