/**
 * Created by 胡子哥 on 2021/09/12.
 */
import {$http} from '@/common/axios';
import {_CONFIG} from '@/config/config';
class ServicesGlobal {
    constructor() {
        this.path = {
            api: _CONFIG.apiPath+"/admin",
            www: _CONFIG.apiPath+"/www",
            apiPublic:_CONFIG.apiPath+"/www/public",
        }
    }

    postParams(obj) {
        let params = new URLSearchParams();
        for (let key in obj) {
            params.append(key, obj[key]);
        }
        return params;
    }

    getMilitias(data={}){
        return $http.get(`/code/get`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    //获取验证码
    getImgCode(data){
        return $http.get(`${this.path.apiPublic}/imgCaptcha`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    upFile(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/articleClass/upFile`, params)
            .then(function (response) {
                return response.data;
            });
    }

    getQrCode(url){
        return $http.get(url)
            .then(function (response) {
                return response.data;
            });
    }

    getTableFields(data = {}) {
        return $http.get(`${this.path.api}/tools/getTableFields`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    // router /boss/team/exportExcel
    exportExcel(routerName, data) {
        let exportMap = {
            "activity_order": "/activityOrder/exportExcel",
            "team": "/team/exportExcel",
        }
        let router = exportMap[routerName]
        return $http.get(`${this.path.api}${router}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    comparePassword(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/boss/comparePassword`, params)
            .then(function (response) {
                return response.data;
            });
    }

    setComparePassword(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/boss/setComparePassword`, params)
            .then(function (response) {
                return response.data;
            });
    }
}

export {
    ServicesGlobal
};