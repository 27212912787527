import datav from "@/layout/Datav";
import platform from "@/layout/Platform";

const adminRoutesIndex = [
  {
    path: "/admin",
    component: platform,
    redirect: "/admin/index",
    name: "admin",
    meta: {
      title: "管理首页",
      classify: "admin",
      icon: "admin",
      group: "admin",
      isOpen: false,
      isShow: 1,
      role: 10,
      authClassify: "home",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/admin/index"),
        name: "adminIndex",
        meta: {
          title: "管理首页",
          classify: "admin",
          icon: "admin",
          group: "admin",
          isOpen: false,
          isShow: 1,
          role: 10,
          authClassify: "home",
        },
      },
    ],
  },
];

const adminRoutesActivity = [
  {
    path: "/datav",
    component: datav,
    redirect: "/datav/all",
    name: "datav",
    meta: {
      title: "数据统计",
      classify: "datav",
      icon: "datav",
      affix: true,
      isHistoryNav: 0,
      isShow: 1,
      role: 10,
      authClassify: "datav",
    },
    children: filterByKey([
      {
        path: "all",
        component: () => import("@/views/datav/all"),
        name: "datavIndex",
        meta: {
          title: "数据统计",
          classify: "datav",
          icon: "datav",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "datav",
        },
      },
    ]),
  },
  {
    path: "/activity",
    component: platform,
    redirect: "/activity/index",
    name: "activity",
    meta: {
      title: "活动管理",
      classify: "activity",
      icon: "activity",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "activity",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/activity/index"),
        name: "activityIndex",
        meta: {
          title: "活动管理",
          classify: "activity",
          icon: "activity",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "activity",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/activity/detail"),
        name: "activityDetail",
        meta: {
          title: "活动详情",
          classify: "activity",
          icon: "activity",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "activity",
        },
      },
    ]),
  },
  {
    path: "/playersRecord",
    component: platform,
    redirect: "/playersRecord/index",
    name: "playersRecord",
    meta: {
      title: "活动订单",
      classify: "playersRecord",
      icon: "playersRecord",
      table: "activity_order",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "activityOrder",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/playersRecord/index"),
        name: "playersRecordIndex",
        meta: {
          title: "活动订单",
          classify: "playersRecord",
          icon: "playersRecord",
          table: "activity_order",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "activityOrder",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/playersRecord/detail"),
        name: "playersRecordDetail",
        meta: {
          title: "活动订单",
          classify: "playersRecord",
          icon: "playersRecord",
          table: "activity_order",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "activityOrder",
        },
      },
    ]),
  },
  {
    path: "/carModels",
    component: platform,
    redirect: "/carModels/index",
    name: "carModels",
    meta: {
      title: "车型管理",
      classify: "carModels",
      icon: "carModels",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "carModels",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/carModels/index"),
        name: "carModelsIndex",
        meta: {
          title: "车型管理",
          classify: "carModels",
          icon: "carModels",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "carModels",
        },
      },
    ]),
  },
  {
    path: "/gift",
    component: platform,
    redirect: "/gift/index",
    name: "gift",
    meta: {
      title: "礼物管理",
      classify: "carModels",
      icon: "carModels",
      affix: true,
      isHistoryNav: 1,
      isShow: 0,
      role: 10,
      authClassify: "gift",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/gift/index"),
        name: "giftIndex",
        meta: {
          title: "礼物管理",
          classify: "gift",
          icon: "gift",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "gift",
        },
      },
    ]),
  },
  {
    path: "/invitedRecord",
    component: platform,
    redirect: "/invitedRecord/index",
    name: "invitedRecord",
    meta: {
      title: "邀请记录",
      classify: "invitedRecord",
      icon: "invitedRecord",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "invitedRecord",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/invitedRecord/index"),
        name: "invitedRecordIndex",
        meta: {
          title: "邀请记录",
          classify: "invitedRecord",
          icon: "invitedRecord",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "invitedRecord",
        },
      },
    ]),
  },
  {
    path: "/blacklist",
    component: platform,
    redirect: "/blacklist/index",
    name: "blacklist",
    meta: {
      title: "屏蔽名单",
      classify: "blacklist",
      icon: "blacklist",
      table: "blacklist",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "blacklist",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/blacklist/index"),
        name: "blacklistIndex",
        meta: {
          title: "屏蔽名单",
          classify: "blacklist",
          icon: "blacklist",
          table: "blacklist",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "blacklist",
        },
      },
    ]),
  },
];

const adminOrganizationRoutes = filterByKey([
  {
    path: "/organization",
    component: platform,
    redirect: "/organization/index",
    name: "organization",
    meta: {
      title: "组织架构",
      classify: "organization",
      icon: "organization",
      affix: true,
      level: 3,
      role: 3,
      isShow: 1,
      authClassify: "organization",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/organization/index"),
        name: "organizationIndex",
        meta: {
          title: "组织架构",
          classify: "organization",
          icon: "organization",
          affix: true,
          level: 3,
          role: 3,
          isShow: 1,
          authClassify: "organization",
        },
      },
    ]),
  },
  {
    path: "/position",
    component: platform,
    redirect: "/position/index",
    name: "position",
    meta: {
      title: "岗位管理",
      classify: "position",
      icon: "position",
      affix: true,
      level: 3,
      role: 3,
      isShow: 1,
      authClassify: "position",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/organization/position"),
        name: "positionIndex",
        meta: {
          title: "岗位管理",
          classify: "position",
          icon: "position",
          affix: true,
          level: 3,
          role: 3,
          isShow: 1,
          authClassify: "position",
        },
      },
    ]),
  },
  {
    path: "/business",
    component: platform,
    redirect: "/business/index",
    name: "business",
    meta: {
      title: "业务人员",
      classify: "business",
      icon: "business",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "business",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/business/index"),
        name: "businessIndex",
        meta: {
          title: "业务人员",
          classify: "business",
          icon: "business",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "business",
        },
      },
      {
        path: "detailsList/:id",
        component: () => import("@/views/business/detailsList"),
        name: "detailsListIndex",
        meta: {
          title: "业务人员推广情况",
          classify: "business",
          icon: "business",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "business",
        },
      },
      {
        path: "shareList/:id",
        component: () => import("@/views/business/shareList"),
        name: "shareListIndex",
        meta: {
          title: "业务人员分享打开情况",
          classify: "business",
          icon: "business",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "business",
        },
      },
    ]),
  },
]);

const adminRoutesTool = [
  {
    path: "/team",
    component: platform,
    redirect: "/team/index",
    name: "team",
    meta: {
      title: "团队信息",
      classify: "team",
      icon: "team",
      affix: true,
      isShow: 1,
      role: 10,
      authClassify: "team",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/team/index"),
        name: "teamIndex",
        meta: {
          title: "团队信息",
          classify: "team",
          icon: "team",
          affix: true,
          isShow: 1,
          role: 10,
          authClassify: "team",
        },
      },
      {
        path: "detail",
        component: () => import("@/views/team/detail"),
        name: "teamDetail",
        meta: {
          title: "团队详情",
          classify: "team",
          icon: "team",
          affix: true,
          isShow: 1,
          role: 10,
          authClassify: "team",
        },
      },
    ]),
  },
  {
    path: "/import",
    component: platform,
    redirect: "/import/index",
    name: "import",
    meta: {
      title: "导入数据",
      classify: "import",
      icon: "import",
      affix: true,
      isShow: 1,
      role: 10,
      authClassify: "import",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/import/index"),
        name: "importIndex",
        meta: {
          title: "导入数据",
          classify: "import",
          icon: "import",
          affix: true,
          isShow: 1,
          role: 10,
          authClassify: "import",
        },
      },
      {
        path: "table",
        component: () => import("@/views/import/table"),
        name: "importTable",
        meta: {
          title: "导入数据",
          classify: "import",
          icon: "import",
          affix: true,
          isShow: 1,
          role: 10,
          authClassify: "import",
        },
      },
    ]),
  },
  {
    path: "/qrcode",
    component: platform,
    redirect: "/qrcode/index",
    name: "qrcode",
    meta: { title: "公众号", classify: "qrcode", icon: "qrcode", affix: true, isShow: 1, role: 10 },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/qrcode/index"),
        name: "qrcodeIndex",
        meta: {
          title: "公众号",
          classify: "qrcode",
          icon: "qrcode",
          affix: true,
          isShow: 1,
          role: 10,
        },
      },
    ]),
  },
];

const authRoutes = [
  {
    path: "/administrator",
    component: platform,
    redirect: "/administrator/index",
    name: "administrator",
    meta: {
      title: "管理员管理",
      classify: "administrator",
      icon: "administrator",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/administrator/index"),
        name: "administratorIndex",
        meta: {
          title: "管理员管理",
          classify: "administrator",
          icon: "administrator",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/administrator/detail"),
        name: "administratorDetail",
        meta: {
          title: "管理员详情",
          classify: "administrator",
          icon: "administrator",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
        },
      },
    ]),
  },
  {
    path: "/auth",
    component: platform,
    redirect: "/auth/index/0",
    name: "auth",
    meta: {
      title: "授权管理",
      classify: "auth",
      icon: "auth",
      affix: true,
      level: 2,
      role: 2,
      isShow: 1,
    },
    children: filterByKey([
      {
        path: "index/:id",
        component: () => import("@/views/auth/index"),
        name: "authIndex",
        meta: {
          title: "授权管理",
          classify: "auth",
          icon: "auth",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
        },
      },
      {
        path: "options",
        component: () => import("@/views/auth/options"),
        name: "authOptions",
        meta: {
          title: "分类选项管理",
          classify: "auth",
          icon: "auth",
          affix: true,
          level: 2,
          role: 2,
          isShow: 1,
        },
      },
    ]),
  },
];

//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj) {
  let index = arrayObj.findIndex((item) => {
    return item["meta"]["isShow"] === 0;
  });
  if (index > -1) {
    arrayObj.splice(index, 1);
  }
  return arrayObj;
}

export { adminRoutesIndex, adminRoutesActivity, adminOrganizationRoutes, adminRoutesTool, authRoutes };
