import {createApp} from 'vue'
import App from './App.vue'

import {router} from '@/router'
import {util} from '@/common/util'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import './css/style.dev.scss';

// 导航守卫文档：https://next.router.vuejs.org/zh/guide/advanced/navigation-guards.html#%E5%85%A8%E5%B1%80%E8%A7%A3%E6%9E%90%E5%AE%88%E5%8D%AB
const whiteList = ['/login']
router.beforeEach((to, from, next) => {
    console.log("toto", to)
    if (to.meta.isOpen) {
        next()
    }

    if (whiteList.indexOf(to.path) > -1) {
        next()
    } else {
        if (!util.getLocalStorage('TOKEN') || !util.getLocalStorage('userInfo')) {
            util.clearLocalStorage()
            next({path: '/login'})
        } else {
            next()
        }
    }
})


router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
})


createApp(App)
    .use(router)
    .use(ElementPlus,{
        locale: zhCn,
    })
    .mount('#app')
