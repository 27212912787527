<template>
  <router-view/>
</template>

<script>
import {util} from "@/common/util";
import SystemServices from "./services/system.js";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      breadCrumbs: [],
    }
  },
  created() {
    this.getSystemConfig()
  },
  methods: {
    async getSystemConfig() {
      await SystemServices.details().then(res => {
        console.log("getSystemConfig",res.Data)
        util.setLocalStorage('systemConfig', res.Data);
      })
    },
  }
}
</script>
<style>
</style>
