/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
const _CONFIG = Object.assign(
  {
    debug: true,
    host: process.env.VUE_APP_HOST,
    apiPath: process.env.VUE_APP_API,
    api: {
      www: process.env.VUE_APP_API + "/www",
      admin: process.env.VUE_APP_API + "/admin",
    },
    apiOSSPath: "https://lingbaikeji-car.oss-cn-beijing.aliyuncs.com/",
    siteUrl: process.env.VUE_APP_HOST,
    h5host: "https://car.lingbaikeji.com",
    cdn: "https://lingbaikeji-car.oss-cn-beijing.aliyuncs.com/",
    rootPath: "",
    siteName: "车之友",
    systemFullName: "车之友",
    shortName: "车之友",
    column: [],
    prefix: "LB-HK-ADMIN",
  },
  {}
);

export { _CONFIG };
