import index from "@/layout/Index";
import open from "@/layout/Open";

const openRoutes = [
    {
        path: '/',
        component: index,
        redirect: '/login',
        name: "index",
        meta: {title: '首页', classify: 'index', icon: 'index', group:'guest', isOpen: false, isShow: 0},
        children: [
            /*{
                path: '/index',
                component: () => import('@/views/index'),
                name: 'index',
                meta: {title: '首页', classify: 'index', icon: 'index',group:'guest', isOpen: false, isShow: 0}
            }*/
        ]
    },
    {
        path: '/login',
        component: open,
        redirect: '/login',
        name: "login",
        meta: {title: '登录', classify: 'login', icon: 'login', group:'guest',isOpen: true, isShow: 0},
        children: [
            {
                path: '/login',
                component: () => import('@/views/login'),
                name: 'Login',
                meta: {title: '登录', classify: 'login', icon: 'login', group:'guest',isOpen: true, isShow: 0}
            }
        ]
    },
]

export {
    openRoutes
}