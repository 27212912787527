let _OPTIONS = {
    "teamType": [
        {"label": "个人", "value": 1, "checked": 0, "disabled": true},
        {"label": "企业", "value": 2, "checked": 0, "disabled": false},
        {"label": "政务", "value": 3, "checked": 0, "disabled": false},
        {"label": "学校", "value": 4, "checked": 0, "disabled": false},
    ],
    "adminRole": [
        {"label": "系统", "value": 1, "checked": 0, "disabled": true},
        {"label": "团队负责人", "value": 2, "checked": 0, "disabled": false},
        {"label": "普通管理员", "value": 10, "checked": 0, "disabled": false},
    ],
    "userRole": [
        {"label": "采购", "value": 1, "checked": 0, "disabled": true},
        {"label": "出纳", "value": 2, "checked": 0, "disabled": false},
        {"label": "员工", "value": 8, "checked": 0, "disabled": false},
        {"label": "商务会员", "value": 9, "checked": 0, "disabled": false},
    ],
    "adminLevel": [
        {"label": "ROOT", "value": 1, "checked": 0, "disabled": true},
        {"label": "系统管理员", "value": 2, "checked": 0, "disabled": true},
        {"label": "超级管理员", "value": 3, "checked": 0, "disabled": false},
        {"label": "高级管理员", "value": 4, "checked": 0, "disabled": false},
        {"label": "普通管理员", "value": 5, "checked": 1, "disabled": false},
    ],
    "userLevel": [
        {"label": "A级", "value": 1, "checked": 0, "disabled": false},
        {"label": "B级", "value": 2, "checked": 0, "disabled": false},
        {"label": "C级", "value": 3, "checked": 0, "disabled": false},
        {"label": "D级", "value": 4, "checked": 0, "disabled": false},
        {"label": "E级", "value": 5, "checked": 0, "disabled": false},
        {"label": "F级", "value": 6, "checked": 0, "disabled": false},
        {"label": "G级", "value": 7, "checked": 0, "disabled": false},
        {"label": "H级", "value": 8, "checked": 0, "disabled": false},
        {"label": "I级", "value": 9, "checked": 0, "disabled": false},
        {"label": "J级", "value": 10, "checked": 0, "disabled": false},
    ],
    "articleAttr": [
        {"label": "普通", "value": 1, "checked": 1},
        {"label": "推荐", "value": 2, "checked": 0},
        {"label": "置顶", "value": 3, "checked": 0},
        {"label": "头条", "value": 4, "checked": 0},
    ],
    "status": [
        {"label": "正常", "value": 1, "checked": 1},
        {"label": "回收", "value": 2, "checked": 0},
    ],
    "statusUser": [
        {"label": "正常", "value": 1, "checked": 1},
        {"label": "禁用", "value": 2, "checked": 0},
    ],
    "isDelete":[
        {"label": "已离职", "value": 1, "checked": 0},
        {"label": "在职", "value": 2, "checked": 0},
    ],
    "statusGoods": [
        {"label": "正常售卖", "value": 1, "checked": 1},
        {"label": "商品下线", "value": 2, "checked": 0},
    ],
    "payStatus": [
        {"label": "已支付", "value": 1, "checked": 0},
        {"label": "待支付", "value": 2, "checked": 0},
        {"label": "支付失败", "value": 3, "checked": 0},
        {"label": "待确认", "value": 4, "checked": 0},
        /*{"label": "已取消", "value": 5, "checked": 0},
        {"label": "已完成", "value": 6, "checked": 0},*/
    ],
    "activatedStatus": [
        {"label": "已激活", "value": 1, "checked": 1},
        {"label": "未激活", "value": 2, "checked": 0},
    ],
    "issuedStatus": [
        {"label": "是", "value": 1, "checked": 1},
        {"label": "否", "value": 2, "checked": 0},
    ],
    "cardStatus": [
        {"label": "正常", "value": 1, "checked": 1},
        {"label": "待充值", "value": 2, "checked": 0},
        {"label": "已禁用", "value": 3, "checked": 0},
    ],
    "payment": [
        {"label": "线下对公账号", "value": 1, "checked": 0},
        {"label": "微信", "value": 2, "checked": 0},
        {"label": "支付宝", "value": 3, "checked": 0},
    ],
    "orderTypeGroup": [
        {"label": "全部订单", "value": 0, "checked": 0},
        {"label": "商务自选", "value": 3, "checked": 0},
        {"label": "员工订单", "value": 2, "checked": 0},
    ],
    "auditStatus": [
        {"label": "通过", "value": 1, "checked": 1},
        {"label": "审核中", "value": 2, "checked": 0},
        {"label": "不通过", "value": 3, "checked": 0},
    ],
    "categoryType": [
        {"label": "频道", "value": 1, "checked": 0},
        {"label": "单页", "value": 2, "checked": 0},
        {"label": "列表", "value": 3, "checked": 0},
        {"label": "外链", "value": 4, "checked": 0}
    ],
    "goodsType": [
        {"label": "实物", "value": 1, "checked": 0},
        {"label": "视频", "value": 2, "checked": 0},
        {"label": "音频", "value": 3, "checked": 0},
        {"label": "电子书", "value": 4, "checked": 0},
    ],
    "taskType": [
        {"label": "互助类", "value": 1, "checked": 0},
        {"label": "悬赏类", "value": 2, "checked": 0},
    ],
    "sex": [
        {"label": "男", "value": 1, "checked": 0},
        {"label": "女", "value": 2, "checked": 0}
    ],
    "noticeType": [
        {"label": "系统消息", "value": 1, "checked": 0},
        {"label": "通知公告", "value": 2, "checked": 1}
    ],
    "writeOff": [
        {
            "label": "已核销",
            "value": 1,
            "checked": 0
        },
        {
            "label": "未核销",
            "value": 2,
            "checked": 0
        },
        {
            "label": "订单异常",
            "value": 3,
            "checked": 0
        }
    ],
    "registered":[
        {
            "label": "已注册",
            "value": 1,
            "checked": 0
        },
        {
            "label": "未注册",
            "value": 2,
            "checked": 0
        },
    ],
    "haveBuy":[
        {
            "label": "已购买",
            "value": 1,
            "checked": 0
        },
        {
            "label": "未购买",
            "value": 2,
            "checked": 0
        },
        {
            "label": "下单未支付",
            "value": 3,
            "checked": 0
        }
    ],
};

export {
    _OPTIONS
}