<template>
  <router-view/>
</template>

<script>
export default {
  mounted() {
    document.getElementById("body").className = "body-datav"
  },
}
</script>

<style lang="scss" scoped>

</style>