import {$http} from '@/common/axios';
import {ServicesGlobal} from './services.js';

class UserServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    create(data = {}) {
        let params = this.postParams(data);
        return $http.post(`{admin}/user/create`, params)
            .then(function (response) {
                return response.data;
            });
    }

    delete(ids,data = {}) {
        let params = this.postParams(data);
        return $http.put(`{admin}/user/delete/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    update(id,data = {}) {
        let params = this.postParams(data);
        return $http.put(`{admin}/user/update/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    updateField(id,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/order/updateField/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    changeStatus(ids,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/user/changeStatus/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    show(data = {}) {
        return $http.get(`{admin}/user/show`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showBusiness(data = {}) {
        return $http.get(`{admin}/user/showBusiness`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    details(id,data = {}) {
        return $http.get(`{admin}/user/details/${id}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showUserAndRechargeRecord(data = {}) {
        return $http.get(`${this.path.api}/user/show`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    resetPassword(id,data = {}){
        let params = this.postParams(data);
        return $http.put(`{admin}/user/resetPassword/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }
}


export default (new UserServices())