<template>
  <PageSidebar></PageSidebar>
  <div class="main">
    <PageHeader :title="pageTitle"></PageHeader>
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageSidebar from "./components/PageSidebar";

export default {
  name: "platformLayout",
  components: {
    PageHeader,
    PageSidebar
  },
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    // 监听路由对象中的变化
    '$route':'handlerRoute'
  },
  mounted() {
    document.getElementById("body").className = "body-height-100"
  },
  methods: {
    handlerRoute(to, from){
      console.log('Route changed from', from, 'to', to);
      this.pageTitle = this.$route.meta.title
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y:auto;

  scrollbar-color:var(--bg-primary-5) var(--bg-primary-3);
}
</style>