<template>
  <WwwHeader></WwwHeader>
  <div className="inbox main">
    <router-view></router-view>
  </div>
  <WwwFooter></WwwFooter>
</template>

<script>
import WwwHeader from "./components/WwwHeader";
import WwwFooter from "./components/WwwFooter";

export default {
  name: "indexLayout",
  components: {
    WwwHeader,
    WwwFooter
  },
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    $route() {

    },
  },
  mounted() {
    document.getElementById("body").className = "body-index"
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../css/_var.scss';
.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

.data-view {
  flex: 1;
  box-sizing: border-box;
  background: #fff;
  padding: 20px 30px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
}
</style>
