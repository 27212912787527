import {$http} from '@/common/axios';
import {ServicesGlobal} from './services.js';

class UserServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    create(data = {}) {
        let params = this.postParams(data);
        return $http.post(`{admin}/activity/create`, params)
            .then(function (response) {
                return response.data;
            });
    }

    delete(ids,data = {}) {
        let params = this.postParams(data);
        return $http.delete(`{admin}/activity/delete/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    update(id,data = {}) {
        let params = this.postParams(data);
        return $http.put(`{admin}/activity/update/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    updateField(id,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/activity/updateField/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    changeStatus(ids,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/activity/changeStatus/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    show(data = {}) {
        return $http.get(`${this.path.api}/activity/show`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    details(id,data = {}) {
        return $http.get(`{admin}/activity/details/${id}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

}


export default (new UserServices())