import { createRouter, createWebHashHistory } from "vue-router";
import { openRoutes } from "./open";
import { adminRoutesIndex, adminRoutesActivity, adminOrganizationRoutes, adminRoutesTool, authRoutes } from "./admin";

const constantRoutes = [
  ...openRoutes,
  ...adminRoutesIndex,
  ...adminRoutesActivity,
  ...adminOrganizationRoutes,
  ...adminRoutesTool,
  ...authRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export {
  adminRoutesIndex,
  adminRoutesActivity,
  adminOrganizationRoutes,
  adminRoutesTool,
  authRoutes,
  constantRoutes,
  router,
};
