<template>
  <div class="sidebar" id="sidebar">
    <div class="site-name" data-path="/" @click="_jump">
      {{ _CONFIG.shortName || "" }}
    </div>
    <!--    <ul class="main-nav">
          <li v-for="(item,index) in adminRoutesIndex" :key="index">
            <router-link :to="{name:item.name}" v-if="item.meta.isShow&&curRole<=item.meta.role">{{ item.meta.title }}</router-link>
          </li>
        </ul>-->

    <!--    <div class="hr"></div>-->
    <template v-if="adminRoutesActivity.length">
      <ul class="main-nav">
        <li v-for="(item, index) in adminRoutesActivity" :key="index">
          <router-link :to="{ name: item.name }" v-if="item.meta.isShow && curRole <= item.meta.role">
            {{ item.meta.title }}
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="adminOrganizationRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in adminOrganizationRoutes" :key="index">
          <router-link :to="{ name: item.name }" v-if="item.meta.isShow && curRole <= item.meta.role">
            {{ item.meta.title }}
          </router-link>
        </li>
      </ul>
    </template>
    <template v-if="adminRoutesTool.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in adminRoutesTool" :key="index">
          <router-link :to="{ name: item.name }" v-if="item.meta.isShow && curRole <= item.meta.role">
            {{ item.meta.title }}
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="authRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item, index) in authRoutes" :key="index">
          <router-link :to="{ name: item.name }" v-if="item.meta.isShow && curRole <= item.meta.role">
            {{ item.meta.title }}
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { adminRoutesIndex, adminRoutesActivity, adminOrganizationRoutes, adminRoutesTool, authRoutes } from "@/router";
import { _data, _methods, util } from "@/common/public";

export default {
  name: "PageSidebar",
  data() {
    return {
      ..._data,
      routes: [],
      adminRoutesIndex: [],
      adminRoutesActivity: [],
      adminOrganizationRoutes: [],
      adminRoutesTool: [],
      authRoutes: [],
      systemRoutes: [],
      curRouteName: "",
      userInfo: {},
      curRole: 0,
      systemConfig: {
        short_name: "",
      },
    };
  },
  created() {
    console.log("this.$route", this.$route);
    this.curRouteName = this.$route.name;
    this.systemConfig = util.getLocalStorage("systemConfig", {});

    this.userInfo = util.getLocalStorage("userInfo", { id: "", role: 100 });
    this.curRole = this.userInfo.role - 0;

    this.processData();
  },
  methods: {
    ..._methods,
    _jump(e) {
      let { path } = e.target.dataset;
      this.$router.push({
        path: path,
      });
    },
    processData() {
      this.auth = this.userInfo.auth ? JSON.parse(this.userInfo.auth) : [];
      this.auth_classify = this.userInfo.auth_classify ? JSON.parse(this.userInfo.auth_classify) : [];
      console.log("this.auth_classify", this.auth_classify);
      this.auth_array = [];
      for (let item of this.auth) {
        this.auth_array.push(item.routeClient);
      }

      this.auth_classify_array = [];
      for (let item of this.auth_classify) {
        this.auth_classify_array.push(item.alias);
      }
      console.log("this.auth_classify_array", this.auth_classify_array);

      this.adminRoutesIndex = this.moveItem(adminRoutesIndex);
      this.adminRoutesActivity = this.moveItem(adminRoutesActivity);
      this.adminOrganizationRoutes = this.moveItem(adminOrganizationRoutes);
      this.adminRoutesTool = this.moveItem(adminRoutesTool);
      this.authRoutes = this.moveItem(authRoutes);

      let authRoutesArray = [
        ...this.adminRoutesIndex,
        ...this.adminRoutesActivity,
        ...this.adminRoutesTool,
        ...this.authRoutes,
      ];
      console.log("authRoutes", authRoutesArray);
    },
    moveItem(arr) {
      if (this.userInfo.role - 0 < 3) {
        return arr;
      }

      let _array = util.extend(true, [], arr);
      for (let i = _array.length - 1; i >= 0; i--) {
        if (_array[i].meta.classify === "home") {
          continue;
        }
        let authClassify = _array[i].meta.authClassify || "";
        if (this.auth_classify_array.indexOf(authClassify) === -1) {
          _array.splice(i, 1);
        } else {
          if (_array[i].children.length) {
            for (let j = _array[i].children.length - 1; j >= 0; j--) {
              let authClassifyItem = _array[i].children[j].meta.authClassify || "";
              if (this.auth_classify_array.indexOf(authClassifyItem) === -1) {
                _array[i].children.splice(j, 1);
              }
            }
          }
        }
      }
      console.log("_array", _array);
      return _array;
    },
  },
};
</script>

<style lang="scss" scoped>
.site-name {
  height: 70px;
  font-size: 26px;
  padding-left: 24px;
  margin-bottom: 8px;
  border-bottom: var(--bg-primary-1) solid 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar {
  width: var(--sidebar-width);
  background: var(--bg-primary);
  color: var(--t-color);
  font-size: 16px;

  height: 100vh;
  overflow: auto;
  border-right: var(--bg-primary-1) solid 1px;

  scrollbar-width: thin;
  scrollbar-color: var(--bg-primary-3) var(--bg-primary-4);

  .sub-title {
    padding: 0 14px 10px;
    line-height: 1.5;
    border-left: #04d45a solid 2px;
    font-size: 18px;
  }

  .sub-title .small {
    font-size: 13px;
  }

  .hr {
    margin-top: 6px;
    margin-bottom: 6px;
    border-bottom: var(--bg-primary-1) solid 1px;
  }

  a {
    font-size: 16px;
    color: var(--t-color-muted);
    display: block;
    line-height: 50px;
    padding: 0 20px;
    border-left: transparent solid 4px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
    &:hover {
      background: var(--bg-primary-hover);
    }
  }
}

.sidebar a.router-link-active {
  border-left-color: var(--color-primary);
  color: var(--color-primary);
}

.selectYear {
  padding: 8px;
  margin-bottom: 8px;
}
</style>
